var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-radio-group",
        {
          staticStyle: { "margin-bottom": "20px" },
          model: {
            value: _vm.tabPosition,
            callback: function ($$v) {
              _vm.tabPosition = $$v
            },
            expression: "tabPosition",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "1" } }, [
            _vm._v("协议异常"),
          ]),
          _c("el-radio-button", { attrs: { label: "2" } }, [
            _vm._v("兑付异常"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabPosition == 1,
              expression: "tabPosition == 1",
            },
          ],
        },
        [_c("xieyi", { ref: "xieyi" })],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabPosition == 2,
              expression: "tabPosition == 2",
            },
          ],
        },
        [_c("duifu", { ref: "duifu" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }