<template>
  <div class="orderTest-container">
    <el-radio-group v-model="tabPosition" style="margin-bottom: 20px">
      <el-radio-button label="1">协议异常</el-radio-button>
      <el-radio-button label="2">兑付异常</el-radio-button>
    </el-radio-group>
    <div v-show="tabPosition == 1">
      <!-- 协议异常 -->
      <xieyi ref="xieyi"></xieyi>
    </div>
    <div v-show="tabPosition == 2">
      <!-- 兑付异常 -->
      <duifu ref="duifu"></duifu>
    </div>
  </div>
</template>
<script>
  import Xieyi from './components/xieyi'
  import Duifu from './components/duifu'
  export default {
    name: 'ListReview',
    components: {
      Xieyi,
      Duifu,
    },
    data() {
      return {
        tabPosition: '1',
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
