var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "cust_id" } },
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "selectDC" },
                on: { "select-id": _vm.clientSelect },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "staff_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "请选择业务员" },
                  model: {
                    value: _vm.form.staff_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staff_id", $$v)
                    },
                    expression: "form.staff_id",
                  },
                },
                _vm._l(_vm.staffList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "model_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "选择陈列活动" },
                  model: {
                    value: _vm.form.model_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "model_id", $$v)
                    },
                    expression: "form.model_id",
                  },
                },
                _vm._l(_vm.activeSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.model_id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "250px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.handleReset } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  item.label == "实兑付/预兑付"
                    ? {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(row.expected) +
                                "/" +
                                _vm._s(row.expect) +
                                " "
                            ),
                          ]
                        },
                      }
                    : null,
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              "min-width": "110px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    row.is_check == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleReview($index, row)
                              },
                            },
                          },
                          [_vm._v(" 核对 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleView($index, row)
                              },
                            },
                          },
                          [_vm._v(" 查看 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("check", { ref: "check", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }