var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
          staticStyle: { "min-height": "800px" },
        },
        [
          _vm.form
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        inline: "",
                        "label-position": "right",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "陈列活动:", prop: "display_name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.display_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "display_name", $$v)
                              },
                              expression: "form.display_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据号:", prop: "no" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.no,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "no", $$v)
                              },
                              expression: "form.no",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "兑付方:", prop: "cust_name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_name", $$v)
                              },
                              expression: "form.cust_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人:", prop: "cust_boss" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_boss,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_boss", $$v)
                              },
                              expression: "form.cust_boss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话:", prop: "cust_mobile" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_mobile", $$v)
                              },
                              expression: "form.cust_mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址:", prop: "cust_address" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_address", $$v)
                              },
                              expression: "form.cust_address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div"),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员:", prop: "staff_name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.staff_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "staff_name", $$v)
                              },
                              expression: "form.staff_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "日期:", prop: "date" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "date", $$v)
                              },
                              expression: "form.date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                        _vm._v("第" + _vm._s(_vm.list1.sort) + "兑付"),
                      ]),
                      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [_vm._v("承兑日期:" + _vm._s(_vm.list1.time))]
                        ),
                        _c("span", [_vm._v("总值:" + _vm._s(_vm.list1.total))]),
                      ]),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            stripe: "",
                            data: _vm.list1.goods,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_name",
                              label: "承兑商品",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unit_name",
                              label: "单位",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_price",
                              label: "单价",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "number",
                              label: "数量",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount",
                              label: "金额",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "remark",
                              label: "备注",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { margin: "10px 0" } }, [
                        _vm._v("兑付现金:" + _vm._s(_vm.list1.total_amount)),
                      ]),
                      _c("div", [_vm._v("备注:" + _vm._s(_vm.list1.remark))]),
                    ],
                    1
                  ),
                  _c(
                    "el-card",
                    { staticClass: "box-card" },
                    [
                      _c("div", { staticStyle: { margin: "10px 0" } }, [
                        _vm._v("已兑付总值" + _vm._s(_vm.list2.total) + "兑付"),
                      ]),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            stripe: "",
                            data: _vm.list2.order,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "bill_code",
                              label: "订单号",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_name",
                              label: "实际兑付",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unit_name",
                              label: "单位",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_price",
                              label: "单价",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quantity",
                              label: "数量",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goods_money",
                              label: "金额",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "info",
                              label: "备注",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "create_at",
                              label: "日期",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "dx" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            attrs: { stripe: "", data: _vm.list3, border: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                width: "130px",
                                prop: "expect",
                                label: "承兑总值",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "130px",
                                prop: "expected",
                                label: "实际兑付",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                width: "130px",
                                prop: "diff",
                                label: "兑付差额",
                                align: "center",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticStyle: { "margin-top": "10px" } }, [
                          _c("i", { staticClass: "el-icon-warning" }),
                          _vm._v(" 兑付差额=实际兑付 - 承兑总值 "),
                        ]),
                      ],
                      1
                    ),
                    !_vm.view
                      ? _c(
                          "div",
                          { staticClass: "b" },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form",
                                attrs: {
                                  inline: "",
                                  "label-position": "right",
                                  "label-width": "120px",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "补差额:", prop: "" } },
                                  [
                                    _vm._l(
                                      _vm.pay_info,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "buchae",
                                            staticStyle: {
                                              "margin-top": "5px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: {
                                                width: "100px",
                                                "margin-right": "10px",
                                              },
                                              model: {
                                                value: item.money,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "money", $$v)
                                                },
                                                expression: "item.money",
                                              },
                                            }),
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "140px" },
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "收款方式",
                                                },
                                                model: {
                                                  value: item.pay_type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "pay_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.pay_type",
                                                },
                                              },
                                              _vm._l(
                                                _vm.payStyle,
                                                function (i, idx) {
                                                  return _c("el-option", {
                                                    key: idx,
                                                    attrs: {
                                                      value: i.id,
                                                      label: i.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-circle-plus addbtn",
                                      on: { click: _vm.addList },
                                    }),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "收款人:", prop: "" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: _vm.skr,
                                          callback: function ($$v) {
                                            _vm.skr = $$v
                                          },
                                          expression: "skr",
                                        },
                                      },
                                      _vm._l(
                                        _vm.selectPeopleList,
                                        function (i, idx) {
                                          return _c("el-option", {
                                            key: idx,
                                            attrs: {
                                              value: i.user_id,
                                              label: i.user_name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticStyle: { "margin-left": "50px" } },
                          [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  stripe: "",
                                  data: _vm.list4,
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "amount",
                                    width: "110px",
                                    label: "补差额",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "user_name",
                                    width: "110px",
                                    label: "收款人",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "pay_type_name",
                                    width: "110px",
                                    label: "收款方式",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "create_at",
                                    width: "130px",
                                    label: "时间",
                                    align: "center",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.view
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleReview } },
                [_vm._v(" 核对完成 ")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }