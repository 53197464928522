<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div
      v-loading="loading"
      class="orderTest-container"
      style="min-height: 800px"
    >
      <div v-if="form">
        <el-form
          ref="form"
          :model="form"
          inline
          label-position="right"
          label-width="120px"
        >
          <el-form-item label="陈列活动:" prop="display_name">
            <el-input
              v-model="form.display_name"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="单据号:" prop="no">
            <el-input
              v-model="form.no"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="兑付方:" prop="cust_name">
            <el-input
              v-model="form.cust_name"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人:" prop="cust_boss">
            <el-input
              v-model="form.cust_boss"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="电话:" prop="cust_mobile">
            <el-input
              v-model="form.cust_mobile"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="地址:" prop="cust_address">
            <el-input
              v-model="form.cust_address"
              style="width: 350px"
              disabled
            ></el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="业务员:" prop="staff_name">
            <el-input
              v-model="form.staff_name"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="日期:" prop="date">
            <el-input
              v-model="form.date"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <el-card class="box-card">
          <div style="margin-bottom: 10px">第{{ list1.sort }}兑付</div>
          <div style="margin-bottom: 10px">
            <span style="margin-right: 10px">承兑日期:{{ list1.time }}</span>
            <span>总值:{{ list1.total }}</span>
          </div>
          <el-table stripe :data="list1.goods" border>
            <el-table-column
              prop="goods_name"
              label="承兑商品"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="unit_name"
              label="单位"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="goods_price"
              label="单价"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="number"
              label="数量"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="amount"
              label="金额"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              align="center"
            ></el-table-column>
          </el-table>
          <div style="margin: 10px 0">兑付现金:{{ list1.total_amount }}</div>
          <div>备注:{{ list1.remark }}</div>
        </el-card>
        <el-card class="box-card">
          <div style="margin: 10px 0">已兑付总值{{ list2.total }}兑付</div>
          <el-table stripe :data="list2.order" border>
            <el-table-column
              prop="bill_code"
              label="订单号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="goods_name"
              label="实际兑付"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="unit_name"
              label="单位"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="goods_price"
              label="单价"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="goods_money"
              label="金额"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="info"
              label="备注"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="create_at"
              label="日期"
              align="center"
            ></el-table-column>
          </el-table>
        </el-card>
        <div class="dx">
          <div>
            <el-table stripe :data="list3" border>
              <el-table-column
                width="130px"
                prop="expect"
                label="承兑总值"
                align="center"
              ></el-table-column>
              <el-table-column
                width="130px"
                prop="expected"
                label="实际兑付"
                align="center"
              ></el-table-column>
              <el-table-column
                width="130px"
                prop="diff"
                label="兑付差额"
                align="center"
              ></el-table-column>
            </el-table>
            <div style="margin-top: 10px">
              <i class="el-icon-warning"></i>
              兑付差额=实际兑付 - 承兑总值
            </div>
          </div>
          <div v-if="!view" class="b">
            <el-form
              ref="form"
              inline
              label-position="right"
              label-width="120px"
            >
              <el-form-item label="补差额:" prop="">
                <div
                  v-for="(item, index) in pay_info"
                  :key="index"
                  class="buchae"
                  style="margin-top: 5px"
                >
                  <el-input
                    v-model="item.money"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <el-select
                    v-model="item.pay_type"
                    clearable
                    style="width: 140px"
                    placeholder="收款方式"
                  >
                    <el-option
                      v-for="(i, idx) in payStyle"
                      :key="idx"
                      :value="i.id"
                      :label="i.name"
                    ></el-option>
                  </el-select>
                </div>
                <i class="el-icon-circle-plus addbtn" @click="addList"></i>
              </el-form-item>
              <el-form-item label="收款人:" prop="">
                <el-select
                  v-model="skr"
                  clearable
                  style="width: 140px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(i, idx) in selectPeopleList"
                    :key="idx"
                    :value="i.user_id"
                    :label="i.user_name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div v-else style="margin-left: 50px">
            <el-table stripe :data="list4" border>
              <el-table-column
                prop="amount"
                width="110px"
                label="补差额"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="user_name"
                width="110px"
                label="收款人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="pay_type_name"
                width="110px"
                label="收款方式"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="create_at"
                width="130px"
                label="时间"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="!view" type="primary" @click="handleReview">
        核对完成
      </el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getStaffList } from '@/api/setPrice'
  import { peopleList } from '@/api/createPreInventory'
  import {
    duifuDetail,
    stopPayList,
    editPayList,
    duifuReview,
  } from '@/api/payListErr'
  export default {
    name: 'Check',
    components: {},
    data() {
      return {
        staffList: [], // 业务员下拉列表
        selectPeopleList: [],
        skr: '',
        id: '129',
        view: false,
        loading: false,
        title: '付费陈列协议',
        showDialog: false,
        form: '',
        time: [],
        list1: [],
        list2: [],
        list3: [],
        list4: [],
        pay_info: [{ pay_type: '', money: '' }],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.list3 = []
        }
      },
    },
    created() {
      // 收款人下拉
      peopleList().then((res) => {
        if (res.code == 200) {
          console.log(res.data)
          this.selectPeopleList = res.data
        }
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await duifuDetail({ id: this.id })
        console.log(2, data)
        this.form = data.info
        this.list1 = data.phase
        this.list2 = data.rel
        this.list4 = data.arr_collect
        this.list3.push(data.diff)
        this.payStyle = data.pay_type
        this.pay_info = [{ pay_type: '', money: '' }]
        this.loading = false
      },
      handleReview() {
        this.pay_info.forEach((item, index) => {
          if (item.money == '') {
            this.pay_info.splice(index, 1)
          }
        })
        let data = JSON.stringify(this.pay_info)
        let end = {
          id: this.id,
          pay_info: data,
          collection_user_id: this.skr,
        }
        duifuReview(end).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
      addList() {
        this.pay_info.push({ pay_type: '', money: '' })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dx {
    display: flex;
    justify-content: flex-start;
    .b {
      width: 470px;
    }
  }
  .addbtn {
    position: absolute;
    top: 8px;
    right: -50px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
</style>
