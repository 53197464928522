<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div
      v-loading="loading"
      class="orderTest-container"
      style="min-height: 800px"
    >
      <div v-if="form">
        <el-form
          ref="form"
          :model="form"
          inline
          label-position="right"
          label-width="120px"
        >
          <el-form-item label="活动名称:" prop="display_name">
            <el-input
              v-model="form.display_name"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="单据号:" prop="no">
            <el-input
              v-model="form.no"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="客户名称:" prop="cust_name">
            <client-search
              ref="clientSearch"
              :disab="view"
              :search="!view"
              :keyword="form.cust_name"
              :popper-class="'selectDC'"
              style="width: 210px"
              @select-val-alldata="clientSelect"
            ></client-search>
          </el-form-item>
          <el-form-item label="联系人:" prop="cust_boss">
            <el-input
              v-model="form.lxr"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="地址:" prop="cust_address">
            <el-input
              v-model="form.cust_address"
              style="width: 550px"
              disabled
            ></el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="参加时间:" prop="create_at_text">
            <el-input
              v-model="form.create_at_text"
              style="width: 210px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="业务员:" prop="create_at_text">
            <el-select
              v-model="form.staff_id"
              clearable
              placeholder="请选择业务员"
              :disabled="view"
              style="width: 210px"
            >
              <el-option
                v-for="item in staffList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="陈列起止日期:" prop="time">
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled="view"
              style="width: 210px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="状态:" prop="step_status_text">
            <el-input
              v-model="form.step_status_text"
              disabled
              style="width: 210px"
            ></el-input>
          </el-form-item>
          <el-form-item label="陈列天数:" prop="show_day">
            <el-input
              v-model="form.show_day"
              disabled
              style="width: 210px"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注:" prop="display_content">
            <el-input
              v-model="form.display_content"
              type="textarea"
              disabled
              style="width: 550px"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table stripe :data="form.modelgoods">
          <el-table-column
            prop="goods_name"
            label="陈列商品"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="display_type_text"
            label="陈列类型"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="number"
            label="数量"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="unit_name"
            label="单位"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            align="center"
          ></el-table-column>
        </el-table>
        <div v-for="(item, index) in form.customercashphase" :key="index">
          <el-card style="margin-top: 5px">
            <div style="margin-bottom: 15px">
              <span style="margin: 0 15px 0 0">
                {{ item.title }}: 承兑日期:{{ item.time_text }}
              </span>
              <span style="margin-right: 15px">
                兑付总值: {{ item.expect }}
              </span>
              已兑付: {{ item.expected }}
            </div>
            <el-table stripe :data="item.goods_info">
              <el-table-column
                v-for="(tableItem, tableIndex) in finallyColumns"
                :key="tableIndex"
                :prop="tableItem.prop"
                :label="tableItem.label"
                :width="tableItem.width"
                align="center"
              >
                <template #default="{ row }">
                  <div v-if="tableItem.label == '兑付商品'">
                    <goods-search
                      ref="goodsSearch"
                      :f-key="row.goods_name"
                      :search="!view"
                      :disab="view"
                      @select-goods-all="goodsSelect($event, row, index)"
                      @add-rows="addRows($event, index)"
                    ></goods-search>
                  </div>
                  <div v-else-if="tableItem.label == '单位'">
                    <el-select
                      v-model="row.unit_id"
                      placeholder="单位"
                      :disabled="view"
                      @change="unitChange($event, row)"
                    >
                      <el-option
                        v-for="(i, idx) in row.arr_unit"
                        :key="idx"
                        :value="i.id"
                        :label="i.unit_name"
                      ></el-option>
                    </el-select>
                  </div>
                  <div v-else-if="tableItem.label == '数量'">
                    <el-input
                      v-model="row.number"
                      type="number"
                      :min="0"
                      :disabled="view"
                    ></el-input>
                  </div>
                  <div v-else-if="tableItem.label == '金额' && !view">
                    {{ Number((row.goods_price * row.number).toFixed(2)) }}
                  </div>
                  <div v-else>
                    {{ row[tableItem.prop] }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="!view"
                prop=""
                align="center"
                label="操作"
                min-width="110px"
              >
                <template #default="{ $index, row }">
                  <el-button
                    type="text"
                    @click.native.prevent="handleCopyRow($index, row, index)"
                  >
                    复制
                  </el-button>
                  <el-button
                    type="text"
                    @click.native.prevent="handleDeleteRow($index, row, index)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
              "
            >
              <div>
                <span style="margin-right: 5px">兑付现金:</span>
                <el-input
                  v-model="item.converted_total_amount"
                  :disabled="view"
                  style="width: 200px"
                ></el-input>
              </div>
              <div>
                <span style="margin-right: 5px">备注:</span>
                <el-input
                  v-model="item.remark"
                  style="width: 300px"
                  :disabled="view"
                ></el-input>
              </div>
            </div>
          </el-card>
        </div>
        <el-form
          ref="form"
          :model="form2"
          inline
          label-position="right"
          label-width="120px"
        >
          <el-form-item label="陈列照片：" prop="">
            <span
              v-for="php in form.photo"
              :key="php"
              style="margin-right: 15px"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="php.file"
                :preview-src-list="pFile"
              ></el-image>
            </span>
          </el-form-item>
        </el-form>
      </div>
      <!-- 照片 -->
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="!view" type="primary" @click="handleReview">
        审核
      </el-button>
      <el-button v-if="!view" type="danger" @click="handleStop">终止</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getStaffList } from '@/api/setPrice'

  import ClientSearch from '@/baseComponents/clientSearch'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { xieyiDetail, stopPayList, editPayList } from '@/api/payListErr'
  export default {
    name: 'Check',
    components: { ClientSearch, GoodsSearch },
    data() {
      return {
        staffList: [], // 业务员下拉列表
        id: '',
        form2: {},
        pFile: [],
        view: false,
        loading: false,
        title: '付费陈列协议',
        showDialog: false,
        form: {},
        time: [],
        checkList: ['兑付商品', '规格', '单位', '价格', '数量', '金额', '备注'],
        columns: [
          {
            order: 1,
            label: '兑付商品',
            prop: 'goods_name',
            width: '200px',
          },
          {
            order: 2,
            label: '规格',
            prop: 'specs',
            width: '120px',
          },
          {
            order: 3,
            label: '单位',
            prop: 'unit',
            width: '110px',
          },
          {
            order: 4,
            label: '价格',
            prop: 'goods_price',
            width: '110px',
          },
          {
            order: 5,
            label: '数量',
            prop: 'number',
            width: '110px',
          },
          {
            order: 6,
            label: '金额',
            prop: 'goods_amount',
            width: '110px',
          },
          {
            order: 7,
            label: '备注',
            prop: 'remark',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time_text = val[0]
          this.form.end_time_text = val[1]
        } else {
          this.form.start_time_text = ''
          this.form.end_time_text = ''
        }
      },
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.form = {}
        }
      },
    },
    created() {
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await xieyiDetail({ d_cust_id: this.id })
        console.log(data)
        this.form = data
        // 处理照片
        this.pFile = []
        this.form.photo.forEach((i) => {
          this.pFile.push(i.file)
        })
        // 处理联系人
        this.form.lxr = this.form.cust_boss + ' ' + this.form.cust_mobile
        // 处理业务员类型
        this.form.staff_id = this.form.staff_id + ''
        // 处理陈列起止日期
        this.time = [this.form.start_time_text, this.form.end_time_text]
        // 处理兑付商品 addrow
        if (!this.view) {
          this.form.customercashphase.forEach((item, index) => {
            item.goods_info.push(this.obj())
          })
        }
        this.loading = false
      },
      obj() {
        return {
          goods_amount: '',
          goods_id: '',
          goods_name: '',
          goods_price: '',
          number: 0,
          remark: '',
          specs: '',
          unit_id: '',
          unit_name: '',
        }
      },
      addRow(index) {
        this.form.customercashphase[index].goods_info.push(this.obj())
      },
      unitChange(val, row) {
        let price =
          row.arr_unit.filter((item) => item.id == val)[0].goods_price ||
          row.arr_unit.filter((item) => item.id == val)[0].old_sell_price
        row.goods_price = price
      },
      clientSelect(val) {
        this.form.cust_id = val.id
        this.form.cust_address = val.address
        this.form.lxr = val.boss + ' ' + val.mobile
      },
      handleReview() {
        let dat = JSON.parse(JSON.stringify(this.form))
        let arr = []
        dat.customercashphase.forEach((item) => {
          arr.push({
            id: item.id,
            remark: item.remark,
            total_amount: item.converted_total_amount,
            goods: item.goods_info,
          })
        })
        arr.forEach((item, index) => {
          item.goods.forEach((i, idx) => {
            if (i.goods_id == '') {
              item.goods.splice(idx, 1)
            }
          })
        })
        let data = {
          d_cust_id: this.id, //ID
          cust_id: this.form.cust_id, //客户ID
          staff_id: this.form.staff_id, //业务员ID
          start_time: this.form.start_time_text, //陈列起日期
          end_time: this.form.end_time_text, //陈列止日期
          display_content: this.form.display_content, //备注
          cashphase: JSON.stringify(arr), //某期兑付
          is_check: '1', //1审核(协议异常用)
        }
        console.log(data)
        editPayList(data).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      handleStop() {
        stopPayList({ d_cust_id: this.id }).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
      goodsSelect(val, row, index) {
        console.log(val)
        row.goods_id = val.goods_id
        row.unit_id = ''
        row.arr_unit = val.arr_unit
        row.specs = val.specs
        row.goods_price = 0
        if (
          this.form.customercashphase[index].goods_info[
            this.form.customercashphase[index].goods_info.length - 1
          ].goods_id !== ''
        ) {
          this.addRow(index)
        }
      },
      addRows(val, index) {
        val.forEach((item) => {
          this.$set(item, 'number', 0)
          this.form.customercashphase[index].goods_info.push(item)
        })
      },
      handleCopyRow(idx, row, index) {
        this.form.customercashphase[index].goods_info.splice(
          idx,
          0,
          JSON.parse(JSON.stringify(row))
        )
      },
      handleDeleteRow(idx, row, index) {
        if (this.form.customercashphase[index].goods_info.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.form.customercashphase[index].goods_info.splice(idx, 1)
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
